
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { CommonHtmlType, NumberOrString } from "@/core/directive/type/common";
import { TaggingItem } from "@/core/directive/interface/common";
import { modalShowListener } from "@/core/directive/function/common";

export default defineComponent({
  name: "order-request-update-shipping-fee-modal",
  props: {
    amount: {
      type: String,
      default: "",
    },
    tax: {
      type: Number,
      default: 0,
    },
    taxArr: {
      type: Array as () => number[],
      default: () => {
        return [];
      },
    },
  },
  components: {},
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { showValidateErrorMsg } = mixin();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const submitButtonRef = ref<Array<HTMLElement | null>>();
    const updateShippingFeeRef = ref<CommonHtmlType>(null);
    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref({
      amount: 0.0,
      tax: 0,
    });

    const rules = ref({
      tax: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const options = ref({
      carrier: [] as TaggingItem[],
    });

    const getFormInfo = () => {
      loading.value = true;
      Promise.all([])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const init = () => {
      setTimeout(() => {
        const amount = Number(props.amount);
        formData.value.amount = amount;
        formData.value.tax = props.tax;
      }, 30);
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate(async (valid: boolean) => {
        if (valid) {
          emit("update-list", formData.value);
          handleDiscard();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-form");
      init();
    };

    const handleDiscard = () => {
      hideModal(updateShippingFeeRef.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      emit("reset-form");
    };

    onMounted(() => {
      // getFormInfo();
      modalShowListener(updateShippingFeeRef.value, () => {
        init();
      });
      modalHideListener(updateShippingFeeRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      formRef,
      submitButtonRef,
      updateShippingFeeRef,
      props,
      loading,
      formData,
      rules,
      options,
      init,
      handleReset,
      handleDiscard,
      resetForm,
      submit,
    };
  },
});
