
import {
  defineComponent,
  ref,
  onMounted,
  getCurrentInstance,
  computed,
} from "vue";
import mixin from "@/mixins";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { modalShowListener, showModal } from "@/core/directive/function/common";
import {
  OrderRequestOverview,
  orderRequestOverviewData,
  OrderRequestOverviewRelationItem,
  OrderRequestOverviewRelationItemEdit,
} from "@/core/directive/interface/orderRequest";
import { CurrencyType } from "@/core/directive/type/order";
import CommonProduct from "@/components/table/CommonProduct.vue";
import { useRoute } from "vue-router";
import { ApiOrderRequest } from "@/core/api";

export default defineComponent({
  components: { CommonProduct },
  name: "order-request-confirm-product-modal",
  props: {
    form: {
      type: Object as () => OrderRequestOverview,
      default: () => {
        return Object.assign({}, orderRequestOverviewData);
      },
    },
    isTaxCount: {
      type: Boolean,
      required: true,
    },
    getNetPrice: {
      type: Function,
      required: true,
    },
    getItemAmountTotal: {
      type: Function,
      required: true,
    },
    getItemVatTotal: {
      type: Function,
      required: true,
    },
  },
  emits: ["update-list", "reset-form", "update-detail", "print-preview"],
  setup(props, context) {
    const { t } = useI18n();
    const route = useRoute();
    const currentInstance: any = getCurrentInstance();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const orderRequestProductRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      confirmedProduct: [] as OrderRequestOverviewRelationItem[],
      editProduct: [] as OrderRequestOverviewRelationItemEdit[],
      rejectedProduct: [] as OrderRequestOverviewRelationItemEdit[],
      isEdit: false,
      isConfirmed: false, // 是否点击过确认按钮
    });

    const rules = ref({});

    const options = ref({});

    const confirmedEditQty = (type: number) => {
      // type == 0表示取消编辑
      formData.value.editProduct.forEach((item) => {
        if (type === 0) {
          item.edit_qty = item.confirmed_qty;
        } else {
          item.confirmed_qty = item.edit_qty;
          const qty = item.request_qty - item.confirmed_qty;
          const rejectedIndex = formData.value.rejectedProduct.findIndex(
            (row) => row.sku_id === item.sku_id
          );
          if (qty > 0) {
            if (rejectedIndex !== -1) {
              formData.value.rejectedProduct[rejectedIndex].request_qty = qty;
              formData.value.rejectedProduct[rejectedIndex].confirmed_qty = qty;
              formData.value.rejectedProduct[rejectedIndex].edit_qty = qty;
            } else {
              formData.value.rejectedProduct.push({
                ...item,
                request_qty: qty,
                confirmed_qty: qty,
                edit_qty: qty,
              });
            }
          } else if (rejectedIndex !== -1) {
            formData.value.rejectedProduct.splice(rejectedIndex, 1);
          }
        }
      });
      if (type == 1) {
        formData.value.isConfirmed = true;
      }
      formData.value.isEdit = false;
    };

    const getSubmitotal = computed(() => {
      let arr: any[] = [];
      formData.value.confirmedProduct.map((item) => {
        const price = props.isTaxCount
          ? item.unit_gross_amount
          : item.unit_net_amount;
        const unitPrice = props.getNetPrice(item);
        const unit_net_amount = props.isTaxCount
          ? props.getItemAmountTotal(
              1,
              price,
              0,
              item.discount_type,
              item.tax_rate,
              true
            )
          : price;
        const unit_gross_amount = props.isTaxCount
          ? price
          : price * (1 + item.tax_rate / 100);
        const total_net_amount = props.getItemAmountTotal(
          item.confirmed_qty,
          unit_net_amount,
          item.discount,
          item.discount_type,
          item.tax_rate,
          false
        );
        const total_gross_amount =
          props.getItemAmountTotal(
            item.confirmed_qty,
            unitPrice,
            item.discount,
            item.discount_type,
            item.tax_rate,
            false
          ) +
          props.getItemVatTotal(
            item.confirmed_qty,
            unitPrice,
            item.discount,
            CurrencyType.Amount,
            item.tax_rate,
            false
          );
        arr.push({
          request_qty: item.request_qty,
          confirmed_qty: item.confirmed_qty,
          unit_net_amount: Number(unit_net_amount).toFixed(2),
          total_net_amount: Number(total_net_amount).toFixed(2),
          unit_gross_amount: Number(unit_gross_amount).toFixed(2),
          total_gross_amount: Number(total_gross_amount).toFixed(2),
          discount_type: item.discount_type,
          discount: item.discount,
          tax_rate: item.tax_rate,
          overall_notes: item.overall_notes,
          currency: item.currency,
          items_id: item.id ? item.id : 0,
          sku_id: item.sku_id,
        });
      });
      return arr;
    });

    const isAllRejectItems = computed(() => {
      let flag = true;
      formData.value.confirmedProduct.map((item) => {
        if (item.confirmed_qty != 0) {
          flag = false;
        }
      });
      if (flag) {
        formData.value.editProduct.map((item) => {
          if (item.confirmed_qty != 0) {
            flag = false;
          }
        });
      }
      return flag;
    });

    const isAllConfirmItems = computed(() => {
      let flag = true;
      formData.value.confirmedProduct.map((item) => {
        if (item.request_qty != item.confirmed_qty) {
          flag = false;
        }
      });
      return flag;
    });

    const getRebuildItems = computed(() => {
      let arr: any[] = [];
      formData.value.editProduct.map((item) => {
        if (item.confirmed_qty > 0) {
          arr.push({
            rebuild_qty: item.confirmed_qty,
            items_id: item.id ? item.id : 0,
          });
        }
      });
      return arr;
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          confirmedOrderRequest();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const confirmedOrderRequest = async () => {
      // Swal.fire({
      //   text: t("orderRequest.confirmedTip"),
      //   icon: "warning",
      //   showCancelButton: true,
      //   buttonsStyling: false,
      //   confirmButtonText: t("common.yes"),
      //   cancelButtonText: t("common.no"),
      //   customClass: {
      //     confirmButton: "btn btn-primary",
      //     cancelButton: "btn btn-danger",
      //   },
      // }).then(async (result) => {
      //   if (result.isConfirmed) {
      loading.value = true;
      const { data } = await ApiOrderRequest.confirmedOrderRequestV3({
        merchant_request_id: route.params.id ? route.params.id : 0,
        merchant: props.form.merchant,
        channel: props.form.channel,
        invoice_address: props.form.invoice_address,
        shipping_address: props.form.shipping_address,
        shipping_charges: props.form.shipping_charges,
        shipping_tax_rate: props.form.shipping_tax_rate,
        reference: props.form.reference,
        overall_notes: props.form.overall_notes,
        relation_items: getSubmitotal.value,
        rebuild_items: getRebuildItems.value,
      });
      loading.value = false;
      if (data.code == 0) {
        if (
          isAllRejectItems.value ||
          isAllConfirmItems.value ||
          getRebuildItems.value.length === 0
        ) {
          showFormSubmitSuccessMsg(() => {
            handleDiscard();
            context.emit("update-list");
          });
        } else {
          context.emit("update-list");
          const html = `<div class="fs-6 fw-bold">
                <h5>${t("orderRequest.submitSuccessfully")}</h5>
                <a href="#/merchant-order/order-overview/${
                  data.data.rebuild_request_id
                }/overview" target="_blank">
                    <span>${t("orderRequest.goNewOrder")}</span>
                    <span class="svg-icon svg-icon-primary svg-icon-1">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor"/>
                            <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor"/>
                        </svg>
                    </span>
                </a>
            </div>`;
          Swal.fire({
            html: html,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("common.okGotIt"),
            customClass: {
              confirmButton: "btn btn-primary",
              container: "order-request-modal-container",
            },
          }).then(() => {
            handleDiscard();
          });
        }
      } else {
        showServerErrorMsg(data);
      }
      //   }
      // });
    };

    const modalShow = () => {
      showModal(orderRequestProductRef.value);
    };

    const handleDiscard = () => {
      hideModal(orderRequestProductRef.value);
    };

    const resetForm = () => {
      //   formRef.value?.resetFields();
      formData.value = {
        confirmedProduct: [],
        editProduct: [],
        rejectedProduct: [],
        isEdit: false,
        isConfirmed: false,
      };
      context.emit("reset-form");
    };

    const init = () => {
      let confirmedList: OrderRequestOverviewRelationItem[] = [],
        editList: OrderRequestOverviewRelationItemEdit[] = [],
        rejectedList: OrderRequestOverviewRelationItemEdit[] = [];
      props.form.relation_items.map((item) => {
        let qty = 0,
          rejectedQty = 0;
        if (typeof item.confirmed_qty === "number") {
          if (item.confirmed_qty >= 0) {
            confirmedList.push(item);
            if (item.confirmed_qty > 0) {
              qty = item.request_qty - item.confirmed_qty;
            } else {
              rejectedQty = item.request_qty;
            }
          }
        } else {
          confirmedList.push({
            ...item,
            confirmed_qty: 0,
          });
          rejectedQty = item.request_qty;
        }
        if (qty > 0) {
          editList.push({
            ...item,
            request_qty: qty,
            confirmed_qty: qty,
            edit_qty: qty,
          });
        }
        if (rejectedQty > 0) {
          editList.push({
            ...item,
            request_qty: rejectedQty,
            confirmed_qty: 0,
            edit_qty: 0,
          });
          rejectedList.push({
            ...item,
            request_qty: rejectedQty,
            confirmed_qty: rejectedQty,
            edit_qty: rejectedQty,
          });
        }
      });
      formData.value.confirmedProduct = confirmedList;
      formData.value.editProduct = editList;
      formData.value.rejectedProduct = rejectedList;
    };

    onMounted(() => {
      modalShowListener(orderRequestProductRef.value, () => {
        init();
      });
      modalHideListener(orderRequestProductRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      CurrencyType,
      loading,
      formData,
      rules,
      options,
      formRef,
      orderRequestProductRef,
      submitButton,
      confirmedEditQty,
      isAllRejectItems,
      isAllConfirmItems,
      getRebuildItems,
      modalShow,
      submit,
      handleDiscard,
      resetForm,
    };
  },
});
