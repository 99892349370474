
import {
  computed,
  defineComponent,
  onMounted,
  onUpdated,
  ref,
  useAttrs,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import FsLightbox from "fslightbox-vue/v3";
import { useI18n } from "vue-i18n";
import mixin from "@/mixins";
import {
  commonBackToList,
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { ApiOrderTracking } from "@/core/api";
import { OrderRequestTrackingShipmentTracking } from "@/core/directive/interface/orderTracking";
import JwtService from "@/core/services/JwtService";
import {
  OrderRequestOverview,
  orderRequestOverviewData,
  OrderRequestOverviewRelationItem,
} from "@/core/directive/interface/orderRequest";
import { OrderTrackingShipmentTrackingKey } from "@/core/directive/type/orderTracking";
import { getOrderTrackingShipmentTrackingKeyMap } from "@/core/directive/function/orderTracking";
import {
  getCurrencyTypeOptions,
  getOrderItemAmountTotal,
  getOrderItemVatTotal,
  previewOrderImages,
  isAuditView,
} from "@/core/directive/function/order";
import UpdateShippingFee from "./UpdateShippingFee.vue";
import {
  OrderRequestPaymentMethod,
  OrderRequestPaymentType,
  OrderRequestProgress,
} from "@/core/directive/type/orderRequest";
import { ApiOrderRequest } from "@/core/api";
import { CurrencyType } from "@/core/directive/type/order";
import UpdateProduct from "./UpdateProduct.vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import UpdateProductNotes from "./UpdateProductNotes.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import RelationFulfillment from "./RelationFulfillment.vue";
import {
  getServiceProviderMap,
  getShipmentStatusMap,
} from "@/core/directive/function/shipment";
import { ProductItemImage } from "@/core/directive/interface/order";
import { CommonDateType } from "@/core/directive/type/common";
import CommonProduct from "@/components/table/CommonProduct.vue";
import UpdateProductNotDate from "./UpdateProductNotDate.vue";
import ConfirmProductModal from "./ConfirmProductModal.vue";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";
import WindowResize from "@/components/layout/WindowResize.vue";
import { checkPermissionFlag } from "@/directives";
import PrintPreview from "@/components/layout/PrintPreview.vue";
import accounting from "accounting-js";

export default defineComponent({
  name: "merchant-order-order-request-overview",
  components: {
    FsLightbox,
    UpdateShippingFee,
    UpdateProduct,
    UpdateProductNotes,
    RelationFulfillment,
    CommonProduct,
    UpdateProductNotDate,
    ConfirmProductModal,
    PermissionCommon,
    WindowResize,
    PrintPreview,
  },
  setup(props, context) {
    const { t } = useI18n();
    const attrs = useAttrs(); // 接收父路由参数
    const route = useRoute();
    const router = useRouter();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const loading = ref(true);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButtonRef = ref<Array<HTMLElement | null>>([]);
    const confirmProductRef = ref();
    const printPreviewRef = ref();

    const submitButton = (el) => {
      submitButtonRef.value.push(el);
    };

    const setSubmitAttribute = (flag = true) => {
      if (!submitButtonRef.value) return;
      submitButtonRef.value.map((el) => {
        if (flag) {
          el?.setAttribute("data-kt-indicator", "on");
        } else {
          el?.removeAttribute("data-kt-indicator");
        }
      });
    };

    const formData = ref(Object.assign({}, orderRequestOverviewData));
    const inputValue = ref(0);

    const rules = ref({
      merchant: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      channel: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      invoice_address: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      shipping_address: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const OrderRequestTrackingShipmentTracking = ref<
      Array<OrderRequestTrackingShipmentTracking>
    >([]);

    const options = ref({
      postUrl: ApiOrderRequest.printPreviewUrl(),
      toggler: false,
      sources: [] as string[],
      sourcesIndex: -1,
      sourcesSlide: 1,
      discount: getCurrencyTypeOptions(),
      isTaxCount: false,
      tabIndex: attrs.tabIndex as number,
      isEdit: attrs.isEdit as boolean,
      isAdd: attrs.isAdd as boolean,
      isDraft: attrs.isDraft as boolean,
      isAddRouter: attrs.isAddRouter as boolean,
      updateNotDateId: 0,
      updateNotesIndex: 0,
      notes: "",
      date: "",
      scheduleLoading: true,
      shipmentLoading: true,
      shipmentShowDetails: false,
    });

    watch(
      () => attrs.loading,
      (newValue) => {
        loading.value = newValue as boolean;
        const data = attrs.detailData as OrderRequestOverview;
        formData.value = data;
        updateInit();
        getFormInfo();
      }
    );

    watch(
      () => attrs.isEdit,
      (newValue) => {
        options.value.isEdit = newValue as boolean;
      }
    );

    watch(
      () => attrs.isAdd,
      (newValue) => {
        options.value.isAdd = newValue as boolean;
      }
    );

    watch(
      () => attrs.isDraft,
      (newValue) => {
        options.value.isDraft = newValue as boolean;
      }
    );

    watch(
      () => attrs.tabIndex,
      (newValue) => {
        options.value.tabIndex = newValue as number;
      }
    );

    // watch(
    //   () => route.params.id,
    //   (newValue) => {
    //     context.emit("getFormData");
    //     // location.reload();
    //   }
    // );

    const isDoubleSale = computed(() => {
      return formData.value.is_ds_order === 1;
    });

    const isShowItemDescription = computed(() => {
      return (item: OrderRequestOverviewRelationItem) => {
        let flag = false;
        if (options.value.isEdit) {
          flag = true;
        } else {
          if (
            item.inventory_note ||
            item.not_available_until ||
            item.overall_notes
          ) {
            flag = true;
          }
        }
        return flag;
      };
    });

    const changeEdit = () => {
      context.emit("toEdit");
    };

    const updateTab = (index: number) => {
      context.emit("changeTab", index);
    };

    const updateShippingFee = (e) => {
      formData.value.shipping_charges = e.amount;
      formData.value.shipping_tax_rate = e.tax || 0;
    };

    const updateProduct = (data: OrderRequestOverviewRelationItem[]) => {
      let arr: OrderRequestOverviewRelationItem[] = [];
      data.map((item) => {
        if (getSubmitSkuIds.value.indexOf(item.sku_id) === -1) {
          arr.push({
            ...item,
            tax_rate: formData.value.__show.default_tax_rate,
          });
        }
      });
      formData.value.relation_items.push(...arr);
    };

    const handleNotes = (value: string, index: number) => {
      options.value.updateNotesIndex = index;
      options.value.notes = value;
    };

    const handleNotDate = (
      item: OrderRequestOverviewRelationItem,
      index: number
    ) => {
      options.value.updateNotDateId = item.id as number;
      options.value.updateNotesIndex = index;
      options.value.notes = item.inventory_note;
      options.value.date = item.not_available_until;
    };

    const updateProductNotes = (value: string) => {
      formData.value.relation_items[
        options.value.updateNotesIndex
      ].overall_notes = value;
    };

    const updateProductNotDate = (data: any) => {
      formData.value.relation_items[
        options.value.updateNotesIndex
      ].inventory_note = data.inventory_note;
      formData.value.relation_items[
        options.value.updateNotesIndex
      ].not_available_until = data.not_available_until;
    };

    const removeItem = (index: number) => {
      formData.value.relation_items.splice(index, 1);
    };

    const isCanPrint = computed(() => {
      return formData.value.order_progress >= OrderRequestProgress.Confirmed;
      // return checkPermissionFlag(
      //   { auth: ["export"] },
      //   formData.value.order_progress >= OrderRequestProgress.Confirmed
      // );
    });

    const getPrintedSheet = (item) => {
      const params = {
        request_items_id: [item.id],
      };
      printPreviewRef.value?.modalShow(params);
    };

    const getPrintedSheetAll = () => {
      const params = {
        request_items_id: getSubmitIds.value,
      };
      printPreviewRef.value?.modalShow(params);
    };

    const priceChange = (e, index: number, type: string) => {
      const item = formData.value.relation_items[index];
      if (type === "net") {
        item.unit_gross_amount = Number(
          accounting.toFixed(
            item.unit_net_amount * (1 + item.tax_rate / 100),
            2
          )
        );
      } else {
        item.unit_net_amount = Number(
          accounting.toFixed(
            item.unit_gross_amount / (1 + item.tax_rate / 100),
            2
          )
        );
      }
    };

    const isGrossCalculation = computed(() => {
      let value = formData.value.price_calc_mode !== "net";
      if (value) {
        return true;
      }
      return false;
    });

    const getNetPrice = computed(() => {
      return (item) => {
        const price = isGrossCalculation.value
          ? Number(item.unit_gross_amount)
          : Number(item.unit_net_amount);
        return Number(price.toFixed(2));
      };
    });

    const getAmountSubTotal = computed(() => {
      let count = 0;
      formData.value.relation_items.map((item) => {
        count += getOrderItemAmountTotal(
          item.confirmed_qty,
          getNetPrice.value(item),
          item.discount,
          item.discount_type,
          item.tax_rate,
          isGrossCalculation.value
        );
      });
      return count;
    });

    const getAmountVatSubTotal = computed(() => {
      let count = 0;
      formData.value.relation_items.map((item) => {
        const price = getOrderItemAmountTotal(
          item.confirmed_qty,
          getNetPrice.value(item),
          item.discount,
          item.discount_type,
          item.tax_rate,
          isGrossCalculation.value
        );
        count += Number(accounting.toFixed(price, 2));
      });
      return Number(accounting.toFixed(count, 2));
    });

    const diffSubTotalOne = computed(() => {
      return Number(
        accounting.toFixed(
          getAmountSubTotal.value - getAmountVatSubTotal.value,
          2
        )
      );
    });

    const diffSubTotalTwo = computed(() => {
      return Number(
        accounting.toFixed(
          getOrderItemsVatTotal.value - getOrderItemsTotal.value,
          2
        )
      );
    });

    const diffSubTotal = computed(() => {
      // const diff_one = diffSubTotalOne.value;
      // const diff_two = diffSubTotalTwo.value;
      return diffSubTotalOne.value;
    });

    const getVatArr = computed(() => {
      let vatArr: any[] = [],
        vatPercent: number[] = [];
      formData.value.relation_items.map((item) => {
        const price = getNetPrice.value(item);
        let amounts = (Number(item.confirmed_qty) || 0) * Number(price);
        if (vatPercent.indexOf(item.tax_rate) > -1) {
          vatArr[vatPercent.indexOf(item.tax_rate)].base_tax_amount += Number(
            accounting.toFixed(amounts, 2)
          );
        } else {
          vatPercent.push(item.tax_rate);
          vatArr.push({
            tax_percent: item.tax_rate,
            base_tax_amount: amounts,
          });
        }
      });
      return vatArr;
    });

    const getVatItemTotal = computed(() => {
      return (tax: number) => {
        let count = 0;
        formData.value.relation_items.map((item) => {
          if (item.tax_rate === tax) {
            count += getOrderItemVatTotal(
              item.confirmed_qty,
              getNetPrice.value(item),
              item.discount,
              item.discount_type,
              item.tax_rate,
              isGrossCalculation.value
            );
          }
        });
        return Number(accounting.toFixed(count, 2));
      };
    });

    const getVatTotal = computed(() => {
      let count = 0;
      formData.value.relation_items.map((item) => {
        count += getOrderItemVatTotal(
          item.confirmed_qty,
          getNetPrice.value(item),
          item.discount,
          item.discount_type,
          item.tax_rate,
          isGrossCalculation.value
        );
      });
      return count;
    });

    const getVatTotalT = computed(() => {
      let count = 0;
      formData.value.relation_items.map((item) => {
        const price = getOrderItemVatTotal(
          item.confirmed_qty,
          getNetPrice.value(item),
          item.discount,
          item.discount_type,
          item.tax_rate,
          isGrossCalculation.value
        );
        count += Number(accounting.toFixed(price, 2));
      });
      return Number(accounting.toFixed(count, 2));
    });

    const diffVatTotal = computed(() => {
      return Number(
        accounting.toFixed(getVatTotalT.value - getVatTotal.value, 2)
      );
    });

    const getShippingChargesTotal = computed(() => {
      return (
        getOrderItemAmountTotal(
          1,
          Number(formData.value.shipping_charges),
          0,
          CurrencyType.Amount,
          formData.value.shipping_tax_rate,
          false
        ) +
        getOrderItemVatTotal(
          1,
          Number(formData.value.shipping_charges),
          0,
          CurrencyType.Amount,
          formData.value.shipping_tax_rate,
          false
        )
      );
    });

    const getOrderItemsTotal = computed(() => {
      let count = 0;
      count += Number(accounting.toFixed(getAmountSubTotal.value, 2));
      count += Number(accounting.toFixed(getVatTotal.value, 2));
      count += Number(accounting.toFixed(getShippingChargesTotal.value, 2));
      return count;
    });

    const getOrderItemsVatTotal = computed(() => {
      let count = 0;
      count += Number(accounting.toFixed(getAmountVatSubTotal.value, 2));
      count += Number(accounting.toFixed(getVatTotalT.value, 2));
      count += Number(accounting.toFixed(getShippingChargesTotal.value, 2));
      count += getShippingChargesTotal.value;
      return Number(accounting.toFixed(count, 2));
    });

    const getSubmitSkuIds = computed(() => {
      let ids: string[] = [];
      formData.value.relation_items.map((item) => {
        ids.push(item.sku_id);
      });
      return ids;
    });

    const isDisabledSubmit = computed(() => {
      let isflag = false;
      if (formData.value.relation_items.length === 0) {
        isflag = true;
      } else {
        formData.value.relation_items.map((item) => {
          if (
            item.tax_rate !== 0 &&
            formData.value.__show.tax_rates.indexOf(item.tax_rate) === -1
          ) {
            isflag = true;
          }
        });
      }
      return isflag;
    });

    const isShowPaymentInfomationTip = computed(() => {
      let flag = false;
      if (!options.value.isDraft) {
        if (formData.value.relation_payment.payment_method === "") {
          flag = true;
        } else {
          if (
            formData.value.relation_payment.payment_method ===
            OrderRequestPaymentType.DirectPayment
          ) {
            if (formData.value.relation_payment.payment_by === "") {
              flag = true;
            } else if (
              formData.value.relation_payment.payment_by ===
              OrderRequestPaymentMethod.PrivatePerson
            ) {
              if (!formData.value.relation_payment.payment_person) {
                flag = true;
              }
            }
          }
        }
      }
      return flag;
    });

    const showPaymentInfomationTip = () => {
      Swal.fire({
        text: t("orderRequest.paymentErrorTip"),
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t("common.okGotIt"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    };

    const getSubmitIds = computed(() => {
      let arr: any[] = [];
      formData.value.relation_items.map((item) => {
        arr.push(item.id);
      });
      return arr;
    });

    const getSubmitotal = computed(() => {
      let arr: any[] = [];
      formData.value.relation_items.map((item) => {
        if (
          (item.confirmed_qty >= 0 && typeof item.confirmed_qty === "number") ||
          options.value.isAdd
        ) {
          const price = getNetPrice.value(item);
          const qty = options.value.isAdd
            ? item.request_qty
            : item.confirmed_qty;
          const total_net_amount = getOrderItemAmountTotal(
            qty,
            price,
            item.discount,
            item.discount_type,
            item.tax_rate,
            isGrossCalculation.value
          );
          const total_gross_amount =
            getOrderItemAmountTotal(
              qty,
              price,
              item.discount,
              item.discount_type,
              item.tax_rate,
              isGrossCalculation.value
            ) +
            getOrderItemVatTotal(
              qty,
              price,
              item.discount,
              item.discount_type,
              item.tax_rate,
              isGrossCalculation.value
            );
          arr.push({
            request_qty: item.request_qty,
            confirmed_qty: qty,
            unit_net_amount: item.unit_net_amount,
            total_net_amount: Number(total_net_amount).toFixed(2),
            unit_gross_amount: item.unit_gross_amount,
            total_gross_amount: Number(total_gross_amount).toFixed(2),
            discount_type: item.discount_type,
            discount: item.discount,
            tax_rate: item.tax_rate,
            overall_notes: item.overall_notes,
            currency: item.currency,
            items_id: item.id ? item.id : 0,
            sku_id: item.sku_id,
          });
        }
      });
      return arr;
    });

    const changeTax = () => {
      options.value.isTaxCount = !options.value.isTaxCount;
    };

    const previewTrackingImages = (
      previewImages: ProductItemImage[] | undefined,
      imageIndex: number
    ) => {
      options.value.sources = previewOrderImages(previewImages);
      options.value.sourcesIndex = imageIndex;
      options.value.sourcesSlide = imageIndex + 1;
      options.value.toggler = !options.value.toggler;
    };

    const getName = computed(() => {
      return (row) => {
        return JwtService.getLang() === "zh_CN" ? row.name : row.name_en;
      };
    });

    const customFormatDate = (date: CommonDateType, format = "YYYY.MM.DD") => {
      return formatDate(date, format);
    };

    const updateOrderRequestV3 = async () => {
      if (isShowPaymentInfomationTip.value) {
        showPaymentInfomationTip();
        return;
      }
      loading.value = true;
      setSubmitAttribute();
      const { data } = await ApiOrderRequest.updateOrderRequestV3({
        merchant_request_id: route.params.id ? route.params.id : 0,
        merchant: formData.value.merchant,
        channel: formData.value.channel,
        invoice_address: formData.value.invoice_address,
        shipping_address: formData.value.shipping_address,
        shipping_charges: formData.value.shipping_charges,
        shipping_tax_rate: formData.value.shipping_tax_rate,
        reference: formData.value.reference,
        overall_notes: formData.value.overall_notes,
        relation_items: getSubmitotal.value,
      });
      loading.value = false;
      setSubmitAttribute(false);
      if (data.code == 0) {
        updateInit();
        showFormSubmitSuccessMsg(() => {
          context.emit("getFormData");
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const saveOrderRequestV2 = async (type: number) => {
      loading.value = true;
      setSubmitAttribute();
      const { data } = await ApiOrderRequest.saveOrderRequestV2({
        type: type, // 0.草稿；1.Confirmed
        merchant_request_id: route.params.id ? route.params.id : 0,
        merchant: formData.value.merchant,
        channel: formData.value.channel,
        invoice_address: formData.value.invoice_address,
        shipping_address: formData.value.shipping_address,
        shipping_charges: formData.value.shipping_charges,
        shipping_tax_rate: formData.value.shipping_tax_rate,
        reference: formData.value.reference,
        overall_notes: formData.value.overall_notes,
        relation_items: getSubmitotal.value,
      });
      loading.value = false;
      setSubmitAttribute(false);
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          if (options.value.isDraft) {
            refreshPath(data.data.id);
          } else {
            updateInit();
            context.emit("getFormData");
          }
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const updateInitGetFormData = () => {
      updateInit();
      context.emit("getFormData");
      // location.reload();
    };

    const refreshPath = (id) => {
      switch (route.name) {
        case "merchant-order-order-request-overview":
          router
            .replace("/merchant-order/order-request/" + id + "/overview")
            .then(() => updateInitGetFormData());
          break;
        case "merchant-order-order-request-add":
          router
            .replace("/merchant-order/order-request/" + id + "/overview")
            .then(() => updateInitGetFormData());
          break;
        case "merchant-order-order-overview-add":
          router
            .replace("/merchant-order/order-overview/" + id + "/overview")
            .then(() => updateInitGetFormData());
          break;
      }
    };

    const isShowConfirmedModal = computed(() => {
      let flag = false;
      formData.value.relation_items.map((item) => {
        if (
          item.confirmed_qty !== item.request_qty &&
          item.confirmed_qty !== 0 &&
          String(item.confirmed_qty) !== ""
        ) {
          flag = true;
        }
      });
      return flag;
    });

    const confirmedOrderRequest = () => {
      if (isShowPaymentInfomationTip.value) {
        showPaymentInfomationTip();
        return;
      }
      // isShowConfirmedModal.value &&
      if (formData.value.order_progress === OrderRequestProgress.Requested) {
        showConfirmProductModal();
      } else {
        Swal.fire({
          text: t("orderRequest.confirmedTip"),
          icon: "warning",
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: t("common.yes"),
          cancelButtonText: t("common.no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            loading.value = true;
            setSubmitAttribute();
            const { data } = await ApiOrderRequest.confirmedOrderRequestV3({
              merchant_request_id: route.params.id ? route.params.id : 0,
              merchant: formData.value.merchant,
              channel: formData.value.channel,
              invoice_address: formData.value.invoice_address,
              shipping_address: formData.value.shipping_address,
              shipping_charges: formData.value.shipping_charges,
              shipping_tax_rate: formData.value.shipping_tax_rate,
              reference: formData.value.reference,
              overall_notes: formData.value.overall_notes,
              relation_items: getSubmitotal.value,
            });
            loading.value = false;
            setSubmitAttribute(false);
            if (data.code == 0) {
              updateInit();
              showFormSubmitSuccessMsg(() => {
                context.emit("getFormData");
              });
            } else {
              showServerErrorMsg(data);
            }
          }
        });
      }
    };

    const canceledOrderRequest = () => {
      Swal.fire({
        text: t("orderRequest.canceledTip"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          const { data } = await ApiOrderRequest.canceledOrderRequestV2({
            merchant_request_id: route.params.id,
          });
          loading.value = false;
          if (data.code == 0) {
            updateInit();
            showFormSubmitSuccessMsg(() => {
              context.emit("getFormData");
            });
          } else {
            showServerErrorMsg(data);
          }
        }
      });
    };

    const removeDraft = () => {
      Swal.fire({
        title: t("orderRequest.deleteTip"),
        icon: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: t("common.okDeleteIt"),
        cancelButtonText: t("common.nopeClose"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          setSubmitAttribute();
          const { data } = await ApiOrderRequest.deleteOrderRequestV2({
            merchant_request_id: route.params.id,
          });
          loading.value = false;
          setSubmitAttribute(false);
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              backToList();
            });
          } else {
            showServerErrorMsg(data);
          }
        }
      });
    };

    const changeShipmentShow = () => {
      options.value.shipmentShowDetails = !options.value.shipmentShowDetails;
    };

    const backToList = () => {
      const listPath = "/merchant-order/";
      switch (route.name) {
        case "merchant-order-order-request-overview":
          commonBackToList(router, listPath + "order-request");
          break;
        case "merchant-order-order-overview-overview":
          commonBackToList(router, listPath + "order-overview");
          break;
      }
    };

    const OrderRequestLen = ref();
    const getOrderBatchTracking = async () => {
      options.value.shipmentLoading = true;
      const { data } = await ApiOrderTracking.getRequestShipmentTracking({
        merchant_request_id: route.params.id,
      });
      options.value.shipmentLoading = false;
      if (data.code == 0) {
        OrderRequestLen.value = Object.keys(data.data).length;
        let items: OrderRequestTrackingShipmentTracking[] = Object.values(
          data.data
        );
        items.forEach((item) => {
          item.skus = Object.values(item.skus);
        });
        OrderRequestTrackingShipmentTracking.value = items;
      }
    };

    const getFormInfo = () => {
      loading.value = true;
      Promise.all([getOrderBatchTracking()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const showConfirmProductModal = () => {
      confirmProductRef.value?.modalShow();
    };

    const updateDetail = () => {
      updateInit();
      context.emit("getFormData");
    };

    const submit = (type = 0) => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          if (options.value.isEdit) {
            if (route.params.id) {
              if (isShowPaymentInfomationTip.value) {
                showPaymentInfomationTip();
                return;
              }
            }
            if (options.value.isAdd) {
              if (type === 1) {
                Swal.fire({
                  text: t("orderRequest.confirmedTip"),
                  icon: "warning",
                  showCancelButton: true,
                  buttonsStyling: false,
                  confirmButtonText: t("common.yes"),
                  cancelButtonText: t("common.no"),
                  customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger",
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    saveOrderRequestV2(type);
                  }
                });
              } else {
                saveOrderRequestV2(type);
              }
            } else {
              updateOrderRequestV3();
            }
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const updateInit = () => {
      options.value.isTaxCount = isGrossCalculation.value ? true : false;
    };

    const dsTotalGrossAmount = computed(() => {
      return formData.value.relation_items.reduce((sum, item) => {
        return sum + parseFloat(item.total_gross_amount);
      }, 0);
    });

    const dsTotalNetAmount = computed(() => {
      return formData.value.relation_items.reduce((sum, item) => {
        return sum + parseFloat(item.total_net_amount);
      }, 0);
    });

    onMounted(() => {
      setModuleBCN(t, route, router);
      if (!attrs.loading) {
        loading.value = false;
        const data = attrs.detailData as OrderRequestOverview;
        formData.value = data;
        getFormInfo();
      }
    });

    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    return {
      t,
      getOrderItemAmountTotal,
      getOrderItemVatTotal,
      formatDate,
      formatDateTime,
      CurrencyType,
      OrderRequestProgress,
      OrderTrackingShipmentTrackingKey,
      getServiceProviderMap,
      getShipmentStatusMap,
      getOrderTrackingShipmentTrackingKeyMap,
      loading,
      formData,
      inputValue,
      formRef,
      rules,
      submitButton,
      confirmProductRef,
      printPreviewRef,
      options,
      OrderRequestLen,
      OrderRequestTrackingShipmentTracking,
      isDoubleSale,
      isShowItemDescription,
      changeEdit,
      updateTab,
      updateShippingFee,
      updateProduct,
      handleNotes,
      handleNotDate,
      removeItem,
      isCanPrint,
      getPrintedSheet,
      getPrintedSheetAll,
      updateProductNotes,
      updateProductNotDate,
      priceChange,
      isGrossCalculation,
      diffSubTotal,
      diffVatTotal,
      // getVatPrice,
      getNetPrice,
      // getItemAmountTotal,
      // getItemVatTotal,
      getAmountSubTotal,
      getVatArr,
      getVatItemTotal,
      getVatTotal,
      getOrderItemsTotal,
      isDisabledSubmit,
      changeTax,
      previewTrackingImages,
      customFormatDate,
      getName,
      changeShipmentShow,
      updateDetail,
      submit,
      confirmedOrderRequest,
      canceledOrderRequest,
      removeDraft,
      dsTotalGrossAmount,
      dsTotalNetAmount,
      isAuditView,
    };
  },
});
