import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, resolveDirective as _resolveDirective, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "modal_update_product",
  ref: "updateProductRef",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-1000px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = {
  class: "modal-header",
  id: "kt_modal_update_product_header"
}
const _hoisted_5 = { class: "fw-bolder" }
const _hoisted_6 = { class: "fs-6 text-gray-400" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  "data-bs-dismiss": "modal",
  class: "btn btn-icon btn-sm btn-active-icon-primary"
}
const _hoisted_9 = { class: "svg-icon svg-icon-1" }
const _hoisted_10 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_11 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_12 = { class: "svg-icon svg-icon-1 position-absolute ms-4" }
const _hoisted_13 = ["placeholder"]
const _hoisted_14 = { class: "card-box pt-10" }
const _hoisted_15 = {
  key: 0,
  class: "marker",
  src: "media/svg/custom/marker.svg",
  width: "6"
}
const _hoisted_16 = { class: "d-flex select-column" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["checked", "disabled"]
const _hoisted_19 = { class: "modal-footer align-items-center justify-content-end max-sm-justify-content-center" }
const _hoisted_20 = { class: "svg-icon svg-icon-1" }
const _hoisted_21 = {
  type: "submit",
  class: "btn btn-primary"
}
const _hoisted_22 = { class: "svg-icon svg-icon-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_product_info = _resolveComponent("product-info")!
  const _component_sku_info = _resolveComponent("sku-info")!
  const _component_MBDatatablePlus = _resolveComponent("MBDatatablePlus")!
  const _component_el_form = _resolveComponent("el-form")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_form, {
          onSubmit: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.submit()), ["prevent"])),
          model: _ctx.formData,
          rules: _ctx.rules,
          ref: "formRef"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", null, [
                _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.t("orderRequest.searchProducts")), 1),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", {
                    innerHTML: 
                    _ctx.t('orderRequest.searchProductsSubTitle', [_ctx.merchantChannl])
                  
                  }, null, 8, _hoisted_7)
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
                ])
              ])
            ]),
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("span", _hoisted_12, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen021.svg" })
                ]),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.search) = $event)),
                  onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
                  class: "form-control form-control-solid ps-15 top-50",
                  placeholder: _ctx.t('orderRequest.searchProductsPlaceholder')
                }, null, 40, _hoisted_13), [
                  [_vModelText, _ctx.formData.search]
                ]),
                _withDirectives(_createElementVNode("span", {
                  class: "svg-icon svg-icon-1 position-absolute top-50 end-0 mr-2 translate-middle",
                  style: {"cursor":"pointer"},
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.removeSearch && _ctx.removeSearch(...args)))
                }, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
                ], 512), [
                  [_vShow, _ctx.formData.search != '']
                ])
              ]),
              _withDirectives(_createVNode(_component_el_form_item, { prop: "tableHeader" }, null, 512), [
                [_vShow, 0]
              ]),
              _withDirectives(_createVNode(_component_el_form_item, { prop: "search" }, null, 512), [
                [_vShow, 0]
              ]),
              _withDirectives(_createVNode(_component_el_form_item, { prop: "total" }, null, 512), [
                [_vShow, 0]
              ]),
              _withDirectives(_createVNode(_component_el_form_item, { prop: "pageSize" }, null, 512), [
                [_vShow, 0]
              ]),
              _withDirectives(_createVNode(_component_el_form_item, { prop: "currentPage" }, null, 512), [
                [_vShow, 0]
              ]),
              _withDirectives(_createVNode(_component_el_form_item, { prop: "items" }, null, 512), [
                [_vShow, 0]
              ]),
              _withDirectives(_createVNode(_component_el_form_item, { prop: "ids" }, null, 512), [
                [_vShow, 0]
              ]),
              _withDirectives(_createVNode(_component_el_form_item, { prop: "products" }, null, 512), [
                [_vShow, 0]
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("h3", null, _toDisplayString(_ctx.t("orderRequest.results")) + ":", 1),
                _createVNode(_component_MBDatatablePlus, {
                  class: "table-update-product",
                  loading: _ctx.options.productLoading,
                  "table-data": _ctx.formData.items,
                  "table-header": _ctx.formData.tableHeader,
                  "enable-items-per-page-dropdown": true,
                  "current-page": _ctx.formData.currentPage,
                  total: _ctx.formData.total,
                  "rows-per-page": _ctx.formData.pageSize,
                  order: "desc",
                  onCurrentChange: _ctx.onCurrentPageChange,
                  onItemsPerPageChange: _ctx.onRowsPerPageChange,
                  showExpandBtn: false
                }, {
                  "cell-request_check": _withCtx(({ row: item }) => [
                    (_ctx.isStandardSku(item.sku_no))
                      ? (_openBlock(), _createElementBlock("img", _hoisted_15))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("label", {
                        class: _normalizeClass(["form-check form-check-sm form-check-custom form-check-solid", {
                        'cursor-nodrop': _ctx.loading,
                      }]),
                        onClick: _withModifiers(($event: any) => (_ctx.toggleItemSelect(item)), ["stop"])
                      }, [
                        _createElementVNode("input", {
                          class: "form-check-input",
                          type: "checkbox",
                          checked: _ctx.isChecked(item),
                          disabled: _ctx.loading
                        }, null, 8, _hoisted_18)
                      ], 10, _hoisted_17)
                    ])
                  ]),
                  "cell-request_product": _withCtx(({ row: item }) => [
                    _createVNode(_component_product_info, {
                      gallery: [
                      {
                        id: 0,
                        origin_url: item.__show.product_gallery,
                      },
                    ],
                      name: item.__show.product_name,
                      name_en: item.__show.product_name,
                      source_link: '',
                      brand_id: item.brand_name
                    }, null, 8, ["gallery", "name", "name_en", "brand_id"])
                  ]),
                  "cell-request_skuinfo": _withCtx(({ row: item }) => [
                    _createVNode(_component_sku_info, {
                      brand_article_no: item.article_no,
                      color: item.color,
                      size: item.size,
                      product_identifier_id: item.__show.product_identifier_id,
                      "max-width": "400px"
                    }, null, 8, ["brand_article_no", "color", "size", "product_identifier_id"])
                  ]),
                  _: 1
                }, 8, ["loading", "table-data", "table-header", "current-page", "total", "rows-per-page", "onCurrentChange", "onItemsPerPageChange"])
              ])
            ])), [
              [_directive_loading, _ctx.loading]
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-light me-2",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleDiscard && _ctx.handleDiscard(...args)))
              }, [
                _createElementVNode("span", _hoisted_20, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
                ]),
                _createTextVNode(" " + _toDisplayString(_ctx.t("orderRequest.cancel")), 1)
              ]),
              _createElementVNode("button", _hoisted_21, [
                _createElementVNode("span", _hoisted_22, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr075.svg" })
                ]),
                _createTextVNode(" " + _toDisplayString(_ctx.t("orderRequest.addProduct")), 1)
              ])
            ])
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ])
    ])
  ], 512))
}