
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { CommonHtmlType } from "@/core/directive/type/common";
import {
  commonChangeDefaultDate,
  modalShowListener,
} from "@/core/directive/function/common";
import { ApiOrderRequest } from "@/core/api";

export default defineComponent({
  name: "order-request-update-product-notes-modal",
  props: {
    id: {
      type: String,
      default: "",
    },
    notes: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    },
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const updateProductNotDateRef = ref<CommonHtmlType>(null);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButtonRef = ref<Array<HTMLElement | null>>([]);

    const submitButton = (el) => {
      submitButtonRef.value.push(el);
    };

    const setSubmitAttribute = (flag = true) => {
      if (!submitButtonRef.value) return;
      submitButtonRef.value.map((el) => {
        if (flag) {
          el?.setAttribute("data-kt-indicator", "on");
        } else {
          el?.removeAttribute("data-kt-indicator");
        }
      });
    };

    const formData = ref({
      inventory_note: "",
      not_available_until: "",
    });

    const rules = ref({
      // inventory_note: [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      // not_available_until: [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
    });

    const options = ref({});

    const getFormInfo = () => {
      loading.value = true;
      setSubmitAttribute();
      Promise.all([])
        .then(() => {
          loading.value = false;
          setSubmitAttribute(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          if (loading.value) return;
          updateItemProduct();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const updateItemProduct = async () => {
      const params = formData.value;
      loading.value = true;
      const { data } = await ApiOrderRequest.updateOrderRequestV2InventoryNote({
        not_available_until: params.not_available_until,
        inventory_note: params.inventory_note,
        request_items_id: props.id,
      });
      loading.value = false;
      setSubmitAttribute(false);
      if (data.code == 0) {
        emit("update-list", params);
        handleDiscard();
      } else {
        showServerErrorMsg(data);
      }
    };

    const handleDiscard = () => {
      hideModal(updateProductNotDateRef.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      emit("reset-form");
    };

    const init = () => {
      setTimeout(() => {
        formData.value.inventory_note = props.notes;
        formData.value.not_available_until = props.date;
      }, 0);
    };

    onMounted(() => {
      modalShowListener(updateProductNotDateRef.value, () => {
        init();
      });
      modalHideListener(updateProductNotDateRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      commonChangeDefaultDate,
      formRef,
      submitButtonRef,
      submitButton,
      updateProductNotDateRef,
      props,
      loading,
      formData,
      rules,
      options,
      handleDiscard,
      resetForm,
      submit,
    };
  },
});
