
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { CommonHtmlType } from "@/core/directive/type/common";
import {
  commonChangeDefaultDate,
  modalShowListener,
} from "@/core/directive/function/common";

export default defineComponent({
  name: "order-request-update-product-notes-modal",
  props: {
    notes: {
      type: String,
      default: "",
    },
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const updateProductNotesRef = ref<CommonHtmlType>(null);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButtonRef = ref<Array<HTMLElement | null>>([]);

    const submitButton = (el) => {
      submitButtonRef.value.push(el);
    };

    const setSubmitAttribute = (flag = true) => {
      if (!submitButtonRef.value) return;
      submitButtonRef.value.map((el) => {
        if (flag) {
          el?.setAttribute("data-kt-indicator", "on");
        } else {
          el?.removeAttribute("data-kt-indicator");
        }
      });
    };

    const formData = ref({
      overall_notes: "",
    });

    const rules = ref({
      overall_notes: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const options = ref({});

    const getFormInfo = () => {
      loading.value = true;
      setSubmitAttribute();
      Promise.all([])
        .then(() => {
          loading.value = false;
          setSubmitAttribute(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      const overall_notes = formData.value.overall_notes;
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          emit("update-list", overall_notes);
          handleDiscard();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      hideModal(updateProductNotesRef.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      emit("reset-form");
    };

    const init = () => {
      setTimeout(() => {
        formData.value.overall_notes = props.notes;
      }, 0);
    };

    onMounted(() => {
      modalShowListener(updateProductNotesRef.value, () => {
        init();
      });
      modalHideListener(updateProductNotesRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      commonChangeDefaultDate,
      formRef,
      submitButtonRef,
      submitButton,
      updateProductNotesRef,
      props,
      loading,
      formData,
      rules,
      options,
      handleDiscard,
      resetForm,
      submit,
    };
  },
});
